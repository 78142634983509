<template>
	<div>
		<div :id="block.type + block.id" />
	</div>
</template>

<script>
export default {
	name: 'NavigationPanel',
	components: {},
	directives: {},
	props: {
		block: { type: Object, required: true },
	},
	data() {
		return {};
	},
};
</script>
